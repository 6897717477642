import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  Content: {
    padding: "30px"
  },
  subTitle: {
    fontSize: "24px",
    color: "black"
  },
  description: {
    lineHeight: "1.6",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  }
}));

function TermsAndSecurityChildRight({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.Content}>
      {data.map((item) => {
        return (
          <div>
            <Typography className={classes.subTitle}>
              {item.subtitle}
            </Typography>
            {item.description.map((des) => {
              return (
                <p className={classes.description}>
                  {des}
                </p>
              )
            })}
          </div>
        )
      })}
    </div>
  );
}

export default TermsAndSecurityChildRight;