import React from "react"
import { graphql } from "gatsby"
import withRoot from "../../components/shell/withRoot"
import TermsAndSecurityChild from "../../components/help/sections/TermsAndSecurity"

function IndexPage({ data, params }) {
  const { id } = params
  return (
    <React.StrictMode>
      {id && (
        <TermsAndSecurityChild
          data={data.termsAndSecurityJson.termsAndSecurity.termsAndSecurity_content.find(
            i => i.id === id
          )}
          termsAndSecurity={data.termsAndSecurityJson}
        />
      )}
    </React.StrictMode>
  )
}

export default withRoot(IndexPage)
export const query = graphql`
  {
    termsAndSecurityJson {
      termsAndSecurity {
        title
        termsAndSecurity_content {
          id
          title
          content {
            description
            subtitle
          }
        }
      }
    }
  }
`
