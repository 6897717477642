
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ALink from '../../common/ALink'
import Typography from "@material-ui/core/Typography";
import TermsAndSecurityContents from "./TermsAndSecurityContents";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            width: "100%",
            maxHeight: "auto",
            alignItems: "stretch",
            fontWeight: '400',

        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            display: "block",
            fontWeight: '300',
        }
    },
    Title: {
        width: "30%",
        padding: "30px",
        borderRight: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            borderBottom: "1px solid #005CB2",
        }
    },
    TitleChild: {
        marginBottom: "16px",

    },
    Content: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        }
    },
    link: {
        textDecoration: 'none',
        color: "black",
    },
    linkHove: {
        textDecoration: 'none',
        color: "#005CB2",
    },

    help_title: {
        color: "#005CB2",
        marginBottom: "16px",
        fontSize: "24px"
    },

}));

export default function TermsAndSecurity({ data, termsAndSecurity }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.Title}>
                <Typography Title className={classes.help_title}>
                    {termsAndSecurity.termsAndSecurity.title}
                </Typography>
                {termsAndSecurity.termsAndSecurity.termsAndSecurity_content.map((item) => {
                    return (
                        <div>
                            <ALink
                                className={
                                    item.id === data.id
                                        ? classes.linkHove
                                        : classes.link
                                }
                                to={`/terms-and-security/${item.id}`}>
                                <p className={classes.TitleChild} >
                                    {item.title}
                                </p>
                            </ALink>

                        </div>
                    )
                })}
            </div>
            <div className={classes.Content}>
                <TermsAndSecurityContents data={data.content} />
            </div>
        </div>
    )
}
